.kanubuchung {
    margin: 1em 1em 1em 1em;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #dfceac !important;
}

.alert {
    position: relative;
    padding: 14px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px solid;
    border-radius: 6px;
}

.alert > p {
    margin: 0 0 0 0;
}

body {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-family: Magra;
}

/* magra-regular - latin-ext_latin */
@font-face {
    font-family: 'Magra';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Magra'),
    url('magra-v5-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('magra-v5-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

h1 {
    margin-top: .2em;
    margin-bottom: .5em;
    font-size: 36px;
}

label {
    display: block;
    margin: 0 0 .2em 0;
    font-weight: bold;
}

input, select, textarea {
    margin: 0 0 1em 0;
}

.error {
    margin: 0 0 1em 0;
}

button {
    display: table;
}

.button-inline {
    display: inline !important;
}

.container {
    max-width: 420px;
    display: flex;
}

.container.space-around {
    justify-content: space-around;
}

.container.space-between {
    justify-content: space-between;
}

.no-underline {
    text-decoration: none;
}

hr {
    margin-left: 0px;
    margin-top: 3.5em;
    max-width: 420px;
}

.pflichtfeld-hinweis {
    font-weight: lighter;
    font-size: 15px;
}

.small-bottom-margin {
    margin-bottom: .1em;
}

.no-bottom-margin {
    margin-bottom: 0em;
}

#datum-hinweis {
    margin-top: 0em;
    margin-bottom: 0.6em;
}

#kanadier-hinweis, #kajaks-hinweis, #sups-hinweis {
    margin-top: .1em;
    margin-bottom: 1em;
}

#kanadier-hinweis > p, #kajaks-hinweis > p, #sups-hinweis > p {
    margin-top: .1em;
    margin-bottom: 1em;
}

input[type="checkbox"] {
    height: 1.15em;
    width: 1.15em;
}

@media (prefers-color-scheme: dark) {
    body, input, select, textarea {
        background-color: rgba(0, 0, 0, .85);
        color: rgb(240, 240, 240);
    }

    button {
        color: black !important;
    }

    .error {
        color: indianred;
    }

    .pflichtfeld-hinweis {
        color: rgb(180, 180, 180);
    }


    /*button,
    input,
    select,
    textarea {
        font-family: inherit;
        font-size: 100%;
        vertical-align: baseline;
        border: 0;
        outline: 0;
        color: #fff;
    }*/
    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    textarea {
        overflow: auto;
        vertical-align: top;
        resize: none;
    }

    [placeholder]::-webkit-input-placeholder {
        color: rgba(255, 255, 255, .8);
    }

    [placeholder]:hover::-webkit-input-placeholder {
        color: rgba(255, 255, 255, .4);
    }

    [placeholder]:focus::-webkit-input-placeholder {
        color: transparent;
    }

    [placeholder]::-moz-placeholder {
        color: rgba(255, 255, 255, .8);
    }

    [placeholder]:hover::-moz-placeholder {
        color: rgba(255, 255, 255, .4);
    }

    [placeholder]:focus::-moz-placeholder {
        color: transparent;
    }

    [placeholder]:-ms-input-placeholder {
        color: rgba(255, 255, 255, .8);
    }

    [placeholder]:hover:-ms-input-placeholder {
        color: rgba(255, 255, 255, .4);
    }

    [placeholder]:focus:-ms-input-placeholder {
        color: transparent;
    }

    form {
        width: 400px;
        margin: 50px auto;
    }

    input[type="text"], input[type="tel"], input[type="date"], input[type="time"], input[type="email"], select {
        padding: 4px 8px 6px 8px;
        border: 1px solid rgba(0, 0, 0, .5);
        background: rgba(0, 0, 0, .25);
    }

    textarea {
        display: block;
        margin: 0 0 20px;
        padding: 8px 12px 10px 12px;
        border: 1px solid rgba(0, 0, 0, .5);
        background: rgba(0, 0, 0, .25);
    }

    button[type="button"] {
        padding: 8px 8px 8px 8px;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, .5);
        background: rgba(0, 0, 0, .25);
        color: azure !important;
    }

    a {
        color: lightblue;
    }

    img {
        filter: brightness(.97) contrast(.9);
    }

}

@media (prefers-color-scheme: light) {
    body {
        color: #191919;
    }

    button {
        color: black !important;
    }

    .error {
        color: red;
    }

    .pflichtfeld-hinweis {
        color: rgb(118, 118, 118);
    }

}

.validation-error {
    box-shadow: 0 0 5px 1px red;
}

.validation-error:focus {
    box-shadow: none;
}